<template>
    <div>
        <h3>Participent à l'événement :</h3>
        <ul v-if="evenement.participants.length">
            <li v-for="participant in evenement.participants" :key="'participant_' + participant.id">
                <user-name :user="participant"/>
                <text-delete-button v-if="isCurrentlyLoggedInOrBureau(participant)"
                                    @click.native="deleteParticipant(participant)"
                                    class="ml-1"/>
            </li>
        </ul>
        <p v-else>Personne pour l'instant</p>
        <b-button v-if="canSubscribe()" variant="success" @click="subscribe">M'inscrire à l'événement</b-button>
    </div>
</template>

<script>
    const TextDeleteButton = () => import('@/components/text_button/TextDeleteButton');
    const UserName         = () => import('@/components/UserName');

    import {isUser, isCurrentlyLoggedInOrBureau} from '@/util/auth';
    import {apiPath}                             from '@/util/http';
    import alert                                 from '@/util/alert';
    import {textUsername}                        from '@/util/text';

    import {mapState} from 'vuex';

    export default {
        name: "EvenementParticipations",
        components: {TextDeleteButton, UserName},
        props: {
            evenement: {
                type: Object,
                required: true
            }
        },
        computed: {
            ...mapState({user: 'user'})
        },
        methods: {
            isCurrentlyLoggedInOrBureau,
            canSubscribe() {
                return isUser() && !this.evenement.participants.find(participant => participant.id === this.user.utilisateur.id);
            },
            deleteParticipant(participant) {
                alert.confirm(
                    'Supprimer la participation de ' + textUsername(participant) + ' ?',
                    () => this.axios
                        .delete(apiPath('delete_participation_event', {
                            evenement: this.evenement.id,
                            participant: participant.id
                        }))
                        .then(() => {
                            this.$toaster.success('Participation supprimée');
                            this.$emit('edit');
                        })
                        .catch(() => this.$toaster.error('Impossible de supprimer la participation'))
                        .finally(alert.stopLoading)
                );
            },
            subscribe() {
                alert.loading();
                this.axios.post(apiPath('subscribe_event', {evenement: this.evenement.id}))
                    .then(() => {
                        this.$toaster.success('Participation enregistrée');
                        this.$emit('edit');
                    })
                    .catch(() => this.$toaster.error("Impossible d'enregistrer votre participation"))
                    .finally(alert.stopLoading);
            }
        }
    }
</script>
